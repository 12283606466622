/************
 * Author : Suriya V
 * Developed by : Shiva Software Solutions
 * Date : 28-06-2021
 * Descriptions : To call Sigin for gudmed operators and admin design page
 * Dependicies : To use react-router-dom,@material-ui/core,formik
 * Modified Date :28-06-2021
 * Modified Author Name :Mangaleswari P
 * Modified Fuction :Regular Expression declaration using constant
 ************/
export const authRole = {
  admin: ['admin'],
  report: ['report'],
  user: ['L1 Operator', 'L2 Operator', 'L3 Operator', 'Admin'],
  amazonUser: ['Admin'],
  digitizationUser: ['Admin', 'L3 Operator'],
  operator: ['L1 Operator', 'L2 Operator', 'L3 Operator'],
  adminRoles: [1],
  admins: ['Admin'],
  dashboardRoles: [1, 2, 3, 4],
  digitizationdashboardRoles: [1, 4],
  operatorRoles: [2, 3, 4],
  mergedoctorRoles: [3],
  amazonAdminRoles: [1],
};

export const userRole = {
  user: [
    {
      roleId: 2,
      roleName: 'L1 Operator',
    },
    {
      roleId: 3,
      roleName: 'L2 Operator',
    },
    {
      roleId: 4,
      roleName: 'L3 Operator',
    },
    {
      roleId: 1,
      roleName: 'Admin',
    },
  ],
};
export const frequencyUnit = [
  {
    frequencyId: 1,
    frequency: 'DAILY',
  },
  {
    frequencyId: 2,
    frequency: 'WEEKLY',
  },
  {
    frequencyId: 3,
    frequency: 'MONTHLY',
  },
];
export const durationUnit = [
  {
    frequencyId: 1,
    frequency: 'DAYS',
  },
  {
    frequencyId: 2,
    frequency: 'WEEKS',
  },
  {
    frequencyId: 3,
    frequency: 'MONTHS',
  },
];
export const dosageFrequency = [
  {
    frequencyId: 1,
    frequency: 'DAILY',
  },
  {
    frequencyId: 2,
    frequency: 'WEEKLY',
  },
  {
    frequencyId: 3,
    frequency: 'MONTHLY',
  },
];
export const durationFrequency = [
  {
    frequencyId: 1,
    frequency: 'DAYS',
  },
  {
    frequencyId: 2,
    frequency: 'WEEKS',
  },
  {
    frequencyId: 3,
    frequency: 'MONTHS',
  },
];
export const prescription_Type = [
  {
    Id: 1,
    type: ' In-Patient Department',
    dispId: 1,
  },
  {
    Id: 2,
    type: 'Out-Patient Department',
    dispId: 2,
  },
];
export const Docprescription_Type = [
  {
    Id: 3,
    type: 'All',
    dispId: 3,
  },
  {
    Id: 1,
    type: ' In-Patient Department',
    dispId: 1,
  },
  {
    Id: 2,
    type: 'Out-Patient Department',
    dispId: 2,
  },
];
export const all_prescription_Type = [
  {
    Id: -1,
    type: 'All Department',
    dispId: -1,
  },
  {
    Id: 1,
    type: 'In-Patient Department',
    dispId: 1,
  },
  {
    Id: 2,
    type: 'Out-Patient Department',
    dispId: 2,
  },
];

export const report_PresType = [
  {
    Id: 1,
    type: 'IPD',
    dispId: 1,
  },
  {
    Id: 2,
    type: 'OPD',
    dispId: 2,
  },
];
export const all_Presfilter_Type = [
  {
    Id: -1,
    type: 'All',
    dispId: -1,
  },
  {
    Id: 1,
    type: 'IPD',
    dispId: 1,
  },
  {
    Id: 2,
    type: 'OPD',
    dispId: 2,
  },
];

export const default_prescription_Type = {
  Id: -1,
  type: 'All',
  dispId: -1,
};

export const itemStatus = [
  {
    itemId: 1,
    status: 'APPROVED',
  },
  {
    itemId: 2,
    status: 'REJECTED',
  },
];
export const futureConstruction = [
  {
    itemId: 1,
    status: 'Yes',
  },
  {
    itemId: 2,
    status: 'No',
  },
];

export const userCategory = {
  user: [
    {
      roleId: 1,
      roleName: 'Operators',
    },
    {
      roleId: 2,
      roleName: 'Tele Consultation Doctors',
    },
  ],
};

export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'user',
  photoURL: 'https://via.placeholder.com/150',
};
export const initialUrl = '/dashboard'; // this url will open after login
export const initialUrladmin = '/controlepanel/gudmeduser'; // this url will open after login
//constant for reliance client
export const relianceClientId = 15;
// field validation regualr expressions
export const MobileRegExp = /^[0-9\b]+$/;
export const DurationRegExp = /^[1-9\b]+$/;
export const allowDouble = /^[0-9\b.]+$/;
export const digitRegExp = /^[0-9]*\.?[0-9]*$/;
export const nameRegExp = /[^A-Za-z\s]/;
// export const nameRegExpdot = /[^A-Za-z\s.]/;
export const nameRegExpdot = /^\p{Lu}\p{Ll}+( \p{Lu}\p{Ll}+)*$/;
export const alphabetExpdot = /(^\w{1})|(\s{1}\w{1})/g;
export const nameRegAdvice = /[^A-Za-z\s.,@]/;
export const addressRegExp = /^[a-zA-Z0-9+_.-]+$/;
export const regEx = /^[0-9a-zA-Z]+$/;
/* eslint-disable no-useless-escape */
export const MailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordRegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;
export const AgentIdRegExp = /^[A-z][A-Z][0-9]{4}$/;
export const RegisterRegExp = /^[A-Z0-9-]+$/;
export const CameraRegExp = /^[A-Za-z0-9-]+$/;
export const AvoidSpecialCharacterRegExp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~¬£]/;
export const AvoidSpecialCharacterDrname = /[`!@#$%^&*_+\-=\[\]{};':"\\|,<>\/?~¬£]/;
export const AvoidCharactersforReading = /[`!@#$\[\];':"\\|,\/?~¬£]/;
export const AvoidSpecialCharUserIDRegExp = /[`!@#$%^&*()+\=\[\]{};':"\\|,<>\/?~¬£]/;
export const AvoidSpeCharDigitizationMedRegExp = /[`!@#$%^*()_+\=\[\]{};':"\\|,<>\/?~¬£]/;
export const AvoidWhiteSpaceCharacterReg = /[`!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?~¬£\s]/;
export const AvoidSpecialCharacterDrNAmeRegExp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~¬£]/;
export const AvoidSpecialCharacterDurationRegExp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~¬£A-Za-z]/;
export const AvoidDecimalSpecialCharacterRegExp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~¬£]/;

export const AvoidDigitSpecialCharacterRegExp = /^[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]+$/;

export const AvoidsearchSpecialCharacterRegExp = /[`!@$^*\=\[\]{};"\\|<>\?~¬£]/;
export const AllowSpecialCharacterRegExp = /^[`a-zA-Z0-9 &!.=+<>\-=\[\]\\|{}@#$%~¬£^&*():_/?,;"']+$/;
export const AvoidSpecialCharacterAddressRegExp = /[`!@#$%^*+\=\[\]{};:"\\|<>\?~¬£]/;
export const AvoidSpecialCharacterRegNoRegExp = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\?~¬£]/;
export const AvoidSpecialCharacterUHIDRegExp = /[`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\?~¬£]/;
export const AvoidSpecialCharacterMailRegExp = /[`!#$%^&*()+\=\[\]{};':"\\|,<>\/?~¬£]/;
export const AvoidSpecialCharacterMailRegExpClient = /[`!#$%^&*()+\=\[\]{};':"\\|<>\/?~¬£]/;
export const AvoivitalsmailCharacterReg = /[`\[\];':"\\|<>\/¬£\s]/;
export const AvoidSpecialCharacterhospitalRegExp = /[`!@#$%^*_+\=\[\]{};:"\\|<>\?~¬£]/;
export const AvoidSpecialCharacterDoctorNotesRegExp = /[`!@#$^\=\[\]{};"\\|\~¬£]/;
export const AvoidSearchpatientRegExp = /[`!@#$%^&*()+\=\[\]{};':"\\|,.<>\?~¬£]/;
export const GoogleRecaptchaKey = '6LfH1p4eAAAAAGbTKDroui1_WGKT_T4__PnuZgu7';
export const AllowMobileDot = /^[0-9.]*$/;
export const DepartmentSpecialCharacter = /^[a-zA-Z0-9\-_.&()' ]*$/;
// export const doctorNotesRegExp = /(?<=(?:^|[.?!])\W*)[a-z]/g;
export const genderMaster = [
  {
    genderCode: 1,
    gender: 'Male',
  },
  {genderCode: 2, gender: 'Female'},
  {genderCode: 3, gender: 'Others'},
];

export const clientMaster = {
  clientLogoEng:
    'https://gudmed-images.s3.ap-south-1.amazonaws.com/english_logo.png',
  clientLogoHindi:
    'https://gudmed-images.s3.ap-south-1.amazonaws.com/hindi_logo.png',
};

// tempertaures
export const tempceltofaren = [
  {cell: 35.1, faren: 95.2},
  {cell: 35.2, faren: 95.4},
  {cell: 35.3, faren: 95.6},
  {cell: 35.4, faren: 95.8},
  {cell: 35.6, faren: 96.0},
  {cell: 35.7, faren: 96.2},
  {cell: 35.8, faren: 96.4},
  {cell: 35.9, faren: 96.6},
  {cell: 36.0, faren: 96.8},
  {cell: 36.1, faren: 97.0},
  {cell: 36.2, faren: 97.2},
  {cell: 36.3, faren: 97.4},
  {cell: 36.4, faren: 97.6},
  {cell: 36.5, faren: 97.7},
  {cell: 36.6, faren: 97.8},
  {cell: 36.7, faren: 98.0},
  {cell: 36.8, faren: 98.2},
  {cell: 36.9, faren: 98.4},
  {cell: 37.0, faren: 98.6},
  {cell: 37.1, faren: 98.8},
  {cell: 37.3, faren: 99.2},
  {cell: 37.4, faren: 99.4},
  {cell: 37.6, faren: 99.6},
  {cell: 37.7, faren: 99.8},
  {cell: 37.8, faren: 100.0},
  {cell: 37.9, faren: 100.2},
  {cell: 38.0, faren: 100.4},
  {cell: 38.1, faren: 100.6},
  {cell: 38.2, faren: 100.8},
  {cell: 38.3, faren: 101.0},
  {cell: 38.4, faren: 101.2},
  {cell: 38.6, faren: 101.4},
  {cell: 38.7, faren: 101.6},
  {cell: 38.8, faren: 101.8},
  {cell: 38.9, faren: 102.0},
  {cell: 39.0, faren: 102.2},
  {cell: 39.1, faren: 102.4},
  {cell: 39.2, faren: 102.6},
  {cell: 39.3, faren: 102.8},
  {cell: 39.4, faren: 103.0},
  {cell: 39.7, faren: 103.4},
  {cell: 39.8, faren: 103.6},
  {cell: 39.9, faren: 103.8},
  {cell: 40.0, faren: 104.0},
  {cell: 40.1, faren: 104.2},
  {cell: 40.2, faren: 104.4},
  {cell: 40.3, faren: 104.6},
  {cell: 40.4, faren: 104.8},
  {cell: 40.6, faren: 105.0},
  {cell: 40.7, faren: 105.2},
  {cell: 40.8, faren: 105.4},
  {cell: 40.9, faren: 105.6},
  {cell: 41.0, faren: 105.8},
  {cell: 41.1, faren: 106.0},
  {cell: 41.2, faren: 106.2},
  {cell: 41.3, faren: 106.4},
  {cell: 41.4, faren: 106.6},
  {cell: 41.6, faren: 106.8},
];

export const status = {
  pending: 0,
  inprogress: 1,
  rejected: 2,
  completed: 3,
  sendreview: 4,
  returnBack: 5,
  apierror: 7,
};

export const version = '241103';
export const termsConditions = 'termsConditions';
export const snooze = 'snooze';
export const sendReview = 4;
export const backReview = 5;
export const userRoleMaster = {
  admin: 1,
  L1Operator: 2,
  L2Operator: 3,
  L3Operator: 4,
};

export const permissionMaster = {
  viewEmailId: 1,
  viewMobileno: 2,
  viewHistoricaleRx: 3,
  sendeRxtopatient: 4,
  callback: 5,
  addPrescription: 6,
  sendtoPrescriptionLink: 7,
  viewClientDashboard: 8,
  editPrescription: 9,
  dataTraining: 10,
};

export const serviceList = [
  {seviceId: 'notify', serviceName: 'Reminder'},
  {seviceId: 'event', serviceName: 'Schedule Calender'},
  {seviceId: 'lang', serviceName: 'Language Translation'},
  {seviceId: 'grx', serviceName: 'Generic Medicine'},
  {seviceId: 'callback', serviceName: 'Callback'},
];

export const callBackStatus = {
  callCompleted: 2,
  callNotCompleted: 1,
};

export const callBackStatusList = [
  {id: 0, name: 'Pending'},
  {id: 1, name: 'Call Not Completed'},
];

export const englishLanguage = {
  heading: 'Add Prescription',
  firstName: 'Patient Name',
  patientSuggestions: 'Suggested Patient Info',
  lastName: 'Last Name',
  UHID: 'UHID',
  mobileNo: 'Mobile No.',
  email: 'Email',
  reminder: 'Reminder',
  genericMedicine: 'Generic Medicine',
  needCall: 'Need Call',
  callbackNo: 'Callback Mobile No.',
  uploadImage: 'Upload Prescription',
  uploadReport: 'Upload Reports',
  yes: 'Yes',
  no: 'No',
  save: 'Save',
  browse: 'Browse',
  errImagePdf: 'Please upload prescription is in JPEG/PNG/PDF',
  errPdfonly: 'You can upload only one pdf',
  errImageonly: 'Please upload prescription is in JPEG/PNG',
  errImages30: 'You can upload upto 30 images only',
  errPDF10: 'You can upload upto 10MB per file',
  errMobileNo: 'Please enter valid mobile number',
  errMailId: 'Please enter a valid email',
  errPre: 'please select prescription',
  camera: 'Camera',
  thankyou_msg:
    'Thank you for choosing GUDMED. We have received your prescription',
  thankyou_msg_callback:
    'Thank you for providing your details. A GUDMED counsellor will contact you back once you received the transcribed prescription.(within 30 minutes)',
  notAuthorizedError: `Your account has been blocked as you are trying to access unauthorized page`,
};

export const hindiLanguage = {
  heading: 'दवा का पर्चा भेजें',
  firstName: 'मरीज का नाम',
  patientSuggestions: 'रोगी सुझाव',
  lastName: 'अंतिम नाम',
  UHID: 'यूएचआईडी',
  mobileNo: 'मोबाइल नंबर.',
  email: 'ईमेल',
  reminder: 'रिमाइंडर',
  genericMedicine: 'जेनेरिक दवा',
  needCall: 'कॉल की आवश्यकता है',
  callbackNo: 'Callback Mobile No.',
  uploadImage: 'दवा के पर्चे की फोटो भेजें',
  uploadReport: 'रिपोर्ट की फोटो भेजें',
  yes: 'हाँ',
  no: 'नहीं',
  save: 'सेव करें',
  browse: 'ब्राउज',
  errImagePdf: 'कृपया अपने दवा का परचा निम्न प्रारूप में डालें JPEG/PNG/PDF',
  errPdfonly: 'आप सिर्फ एक PDF भेज सकते हैं',
  errImageonly: 'कृपया अपने दवा का परचा निम्न प्रारूप में डालें JPEG/PNG',
  errImages30: 'आप सिर्फ पर्चे की 30 फोटो भेज सकते हैं',
  errPDF10: 'अधिकतम अपलोड फ़ाइल का आकार 10MB है',
  errMobileNo: 'कृपया सही मोबाइल नंबर डालें',
  errMailId: 'कृपया सही ईमेल डालें',
  errPre: 'दवा का पर्चा चुनें',
  camera: 'कैमरा',
  thankyou_msg:
    'हमारी सेवा का लाभ उठाने के लिए धन्यवाद।  हमें आपके दवा का पर्चा मिल गया है।',
  thankyou_msg_callback:
    'दवा के पर्चे का डिजिटल रूपांतरण आपको मिलने के बाद, गुडमेड के प्रतिनिधि आपको 30 मिनट में कॉल करेंगे।',
};

export const clientID = {
  gudmed: 1,
  redcliffee: 2,
  aiims: 3,
  privatedoctors: 4,
};

export const dateTypes = [
  {name: 'Today', id: 1},
  {name: 'This Week', id: 2},
  {name: 'This Month', id: 3},
  {name: 'Custom Date Range', id: 4},
];

export const Days = [
  {name: 'Monday to Friday', id: 8, checked: false, from: '', to: ''},
  {name: 'Saturday & Sunday', id: 9, checked: false, from: '', to: ''},
  {name: 'Monday', id: 1, checked: false, from: '', to: ''},
  {name: 'Tuesday', id: 2, checked: false, from: '', to: ''},
  {name: 'Wednesday', id: 3, checked: false, from: '', to: ''},
  {name: 'Thursday', id: 4, checked: false, from: '', to: ''},
  {name: 'Friday', id: 5, checked: false, from: '', to: ''},
  {name: 'Saturday', id: 6, checked: false, from: '', to: ''},
  {name: 'Sunday', id: 7, checked: false, from: '', to: ''},
];
export const LocDays = [
  {name: 'Monday', id: 1, checked: false, from: '', to: ''},
  {name: 'Tuesday', id: 2, checked: false, from: '', to: ''},
  {name: 'Wednesday', id: 3, checked: false, from: '', to: ''},
  {name: 'Thursday', id: 4, checked: false, from: '', to: ''},
  {name: 'Friday', id: 5, checked: false, from: '', to: ''},
  {name: 'Saturday', id: 6, checked: false, from: '', to: ''},
  {name: 'Sunday', id: 7, checked: false, from: '', to: ''},
];

export const date_Types = [
  {name: 'Today', id: 1},
  {name: 'This Week', id: 2},
  {name: 'This Month', id: 3},
  {name: 'Custom Date Range', id: 4},
  // {name: 'All Time', id: 5},
];
export const fromtimeSlots = [
  {value: '00:00', id: 1, disabled: false},
  {value: '00:30', id: 2, disabled: false},
  {value: '01:00', id: 3, disabled: false},
  {value: '01:30', id: 4, disabled: false},
  {value: '02:00', id: 5, disabled: false},
  {value: '02:30', id: 6, disabled: false},
  {value: '03:00', id: 7, disabled: false},
  {value: '03:30', id: 8, disabled: false},
  {value: '04:00', id: 9, disabled: false},
  {value: '04:30', id: 10, disabled: false},
  {value: '05:00', id: 11, disabled: false},
  {value: '05:30', id: 12, disabled: false},
  {value: '06:00', id: 13, disabled: false},
  {value: '06:30', id: 14, disabled: false},
  {value: '07:00', id: 15, disabled: false},
  {value: '07:30', id: 16, disabled: false},
  {value: '08:00', id: 17, disabled: false},
  {value: '08:30', id: 18, disabled: false},
  {value: '09:00', id: 19, disabled: false},
  {value: '09:30', id: 20, disabled: false},
  {value: '10:00', id: 21, disabled: false},
  {value: '10:30', id: 22, disabled: false},
  {value: '11:00', id: 23, disabled: false},
  {value: '11:30', id: 24, disabled: false},
  {value: '12:00', id: 25, disabled: false},
  {value: '12:30', id: 26, disabled: false},
  {value: '13:00', id: 27, disabled: false},
  {value: '13:30', id: 28, disabled: false},
  {value: '14:00', id: 29, disabled: false},
  {value: '14:30', id: 30, disabled: false},
  {value: '15:00', id: 31, disabled: false},
  {value: '15:30', id: 32, disabled: false},
  {value: '16:00', id: 33, disabled: false},
  {value: '16:30', id: 34, disabled: false},
  {value: '17:00', id: 35, disabled: false},
  {value: '17:30', id: 36, disabled: false},
  {value: '18:00', id: 37, disabled: false},
  {value: '18:30', id: 38, disabled: false},
  {value: '19:00', id: 39, disabled: false},
  {value: '19:30', id: 40, disabled: false},
  {value: '20:00', id: 41, disabled: false},
  {value: '20:30', id: 42, disabled: false},
  {value: '21:00', id: 43, disabled: false},
  {value: '21:30', id: 44, disabled: false},
  {value: '22:00', id: 45, disabled: false},
  {value: '22:30', id: 46, disabled: false},
  {value: '23:00', id: 47, disabled: false},
  {value: '23:30', id: 48, disabled: false},
];

export const totimeSlots = [
  {value: '00:00', id: 1, disabled: false},
  {value: '00:30', id: 2, disabled: false},
  {value: '01:00', id: 3, disabled: false},
  {value: '01:30', id: 4, disabled: false},
  {value: '02:00', id: 5, disabled: false},
  {value: '02:30', id: 6, disabled: false},
  {value: '03:00', id: 7, disabled: false},
  {value: '03:30', id: 8, disabled: false},
  {value: '04:00', id: 9, disabled: false},
  {value: '04:30', id: 10, disabled: false},
  {value: '05:00', id: 11, disabled: false},
  {value: '05:30', id: 12, disabled: false},
  {value: '06:00', id: 13, disabled: false},
  {value: '06:30', id: 14, disabled: false},
  {value: '07:00', id: 15, disabled: false},
  {value: '07:30', id: 16, disabled: false},
  {value: '08:00', id: 17, disabled: false},
  {value: '08:30', id: 18, disabled: false},
  {value: '09:00', id: 19, disabled: false},
  {value: '09:30', id: 20, disabled: false},
  {value: '10:00', id: 21, disabled: false},
  {value: '10:30', id: 22, disabled: false},
  {value: '11:00', id: 23, disabled: false},
  {value: '11:30', id: 24, disabled: false},
  {value: '12:00', id: 25, disabled: false},
  {value: '12:30', id: 26, disabled: false},
  {value: '13:00', id: 27, disabled: false},
  {value: '13:30', id: 28, disabled: false},
  {value: '14:00', id: 29, disabled: false},
  {value: '14:30', id: 30, disabled: false},
  {value: '15:00', id: 31, disabled: false},
  {value: '15:30', id: 32, disabled: false},
  {value: '16:00', id: 33, disabled: false},
  {value: '16:30', id: 34, disabled: false},
  {value: '17:00', id: 35, disabled: false},
  {value: '17:30', id: 36, disabled: false},
  {value: '18:00', id: 37, disabled: false},
  {value: '18:30', id: 38, disabled: false},
  {value: '19:00', id: 39, disabled: false},
  {value: '19:30', id: 40, disabled: false},
  {value: '20:00', id: 41, disabled: false},
  {value: '20:30', id: 42, disabled: false},
  {value: '21:00', id: 43, disabled: false},
  {value: '21:30', id: 44, disabled: false},
  {value: '22:00', id: 45, disabled: false},
  {value: '22:30', id: 46, disabled: false},
  {value: '23:00', id: 47, disabled: false},
  {value: '23:30', id: 48, disabled: false},
];

export const showDataType = [
  {name: 'Table', id: 1},
  {name: 'Line Chart', id: 2},
];

export const settings = {
  ner_workflow: 'ner_workflow',
};

export const statusTypes = [
  {name: 'Valid prescription', id: 1},
  {name: 'Not a prescription', id: 2},
];

export const medication_Types = [
  {name: 'Acute', id: 1, value: 'ACUTE'},
  {name: 'Chronic', id: 2, value: 'CHRONIC'},
];
